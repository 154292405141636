<template>
  <Layout>
    <Header></Header>
    <Section class="question-section">
      <Row :gutter="15">
        <i-col>
          <div class="video-header">
            <Button
              icon="ios-arrow-back"
              @click="$router.go(-1)"
              type="error"
              size="small"
              class="come-back-button"
              >Geri Dön</Button
            >
            <div class="come-back"></div>
            <hr />
          </div>
        </i-col>
        <i-col :span="12" offset="6">
            <div class="description">
                Eğitmenlerimiz konuları anlamanızda size daha iyi yardımcı olması amacıyla bize göndereceğiniz sorular değerlendirilerek,
                soru-cevap videoları halinde aylık olarak cevaplanacaktır. Kişisel bilgileriniz, cevap videolarında gizli tutulacaktır.
            </div>
            <Form :model="form" class="questions-form" ref="formInline" :rules="rules">
                <FormItem label="Konu" prop="name">
                    <Input v-model="form.name" maxlength="80"/>
                </FormItem>
                <FormItem label="Duyuru" prop="message">
                    <Input v-model="form.message" type="textarea" maxlength="400" show-word-limit :rows="6" />
                </FormItem>
                <Button
                    type="primary"
                    ghost
                    size="small"
                    style="float: right; margin-top: 10px"
                    @click="handleQuestion('formInline')"
                >GÖNDER</Button>
            </Form>
        </i-col>
      </Row>
    </Section>
  </Layout>
</template>

<script>
import { post } from "@/api";
import Header from "@/components/frontend/Header";
export default {
    components: { Header },
    data(){
        return {
            form: {
                user: this.$store.state.users.user._id,
            },
            rules: {
                name: [
                    { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
                ],
                message: [
                    { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
                ],
            }
        }
    },

    methods: {
        async handleQuestion(name) {
            this.$refs[name].validate(async (valid) => {
                if (valid) {
                    const result = await post('/questions/add', this.form);
                    if(result.success){
                        this.$swal('Sorunuz başarı ile gönderildi!');
                        this.form.name = null;
                        this.form.message = null;
                    }
                }
            });
        }
    }
}
</script>

<style scoped>
    .question-section {
    margin: 10px 70px;
    position: relative;
    }

    .come-back-button {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    }

    .description {
        color: #888888;
        font-weight: 700;
        font-size: 18px;
        border: 1px solid #dfdfdf;
        padding: 5px;
        margin-top: 10px;
        background-color: black;
    }

    .questions-form >>> .ivu-input {
    background-color: #151515;
    border: 1px solid #222222;
    }

    .questions-form >>> .ivu-input-word-count {
    background-color: #151515;
    }

    .questions-form >>> .questions-help {
    font-size: 13px;
    line-height: 14px;
    margin-top: 4px;
    }

    .questions-form >>> .ivu-btn {
    font-size: 15px;
    }
</style>