import axios from 'axios';
const apiurl = process.env.VUE_APP_API_URL;

async function get(endpoint, data) {
  try {
    const response = await axios({
      method: 'get',
      params: {
        ...data
      },
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `jwt ${sessionStorage.getItem('token')}`,
        _token: ''
      },
      url: `${apiurl}${endpoint}`,
    });
    if(response.status > 200) {
      throw response.data;
    } else {
      return {
        success: true,
        message: response.data
      }
    }
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }

}

async function post(endpoint, data) {
  const fd = new FormData();
  Object.keys(data).map((e) => {
    if (Array.isArray(data[e])) {
      data[e].map((v) => fd.append([e], v));
    } else {
      fd.append(e, data[e] === null ? '' : data[e]);
    }
  });

    let response;
    try {
      response = await axios({
        data: fd,
        method: 'post',
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: `jwt ${sessionStorage.getItem('token')}`,
          _token: '',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${apiurl}${endpoint}`,
        validateStatus: status => status < 500,
      });
      if(response.status > 200) {
        throw response.data;
      } else {
        return {
          success: true,
          message: response.data
        }
      }
    } catch (e) {
      return {
        success: false,
        error: e,
      };
    } 
}

async function purePost(endpoint, data) {
    let response;
    try {
      response = await axios({
        data,
        method: 'post',
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: `jwt ${sessionStorage.getItem('token')}`,
          _token: '',
        },
        url: `${apiurl}${endpoint}`,
        validateStatus: status => status < 500,
      });
      if(response.status > 200) {
        throw response.data;
      } else {
        return {
          success: true,
          message: response.data
        }
      }
    } catch (e) {
      return {
        success: false,
        error: e,
      };
    } 
}

export {
  post,
  purePost,
  get
}