<template>
  <div class="body-wrapper" :style="bg">
    <div class="logout">
      <Button icon="md-settings" @click="handleAdmin()" v-if="user.authority" type="success" size="small" class="exit">Admin</Button>
      <Button icon="md-close" @click="logout()" type="error" size="small" class="exit">Güvenli Çıkış</Button>
    </div>
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      bg: {
        backgroundImage: `url(${require('@/assets/bg.png')})`
      }
    }
  },

  sockets: {
      connect() {
          // Fired when the socket connects.
          console.log('Socket is connected...');
          this.$socket.emit('drpatoloji', {_id: this.user._id});
      },

      disconnect() {
          console.log('Socket is disconnected...');
      },

      reconnect() {
          this.$socket.emit('drpatoloji', {_id: this.user._id});
          console.log('Socket is reconnected...');
      },
  },

  methods: {
      logout(){
          sessionStorage.removeItem('token');
          this.$store.dispatch('users/logoutUser', { _id: this.user._id });
          this.$router.push('/login');
      },
      handleAdmin() {
        this.$router.push('/admin');
      }
  },

  mounted(){
    this.$socket.emit('drpatoloji', {_id: this.user._id});
  },
  
  computed: {
    ...mapGetters({
      user: 'users/getUser',
    })
  }
}
</script>

<style>
  body {
      background-color: #000000;
  }
  .body-wrapper {
      background-color: #222222;
      height: 100%;
      min-height: 100vh;
      width: 100%;
      background-size: cover;
      background-origin: border-box;
      background-repeat: no-repeat;
  }

  .logout {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .exit {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
  }
</style>