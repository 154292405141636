import { get } from '@/api';
const state = () => ({
    notifications: [],
    notification: null,
    read: false
});

const actions = {
    async getAll({commit}) {
        const r = await get('/notifications');
        if(r) commit('ADD', r.message.data);
    },

    async removeNotice({commit}, id) {
        const r = await get(`/notifications/remove/${id}`);
        if(r.success) commit('REMOVE', id);
    },

    async getLastActive({commit}) {
        const r = await get('/notifications/getlastactive');
        if(r.success && r.message) {
            commit('ADD_LASTNOTIFICATION', r.message.data);
        }
    },

    async setRead({commit}, data) {
        commit('SET_READ', data);
    }
}

const mutations = {
    ADD(state, data) {
        state.notifications = data;
    },
    REMOVE(state, data) {
        state.notifications = state.notifications.filter(v => v._id !== data);
    },
    ADD_LASTNOTIFICATION(state, data) {
        state.notification = data;
    },
    SET_READ(state, data) {
        state.read = data;
    }
}

const getters = {
    getNotifications(state) {
        return state.notifications;
    },
    getNotification(state) {
        return state.notification;
    },
    getReadable(state) {
        return state.read;
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}