<template>
    <Section class="search-section">
        <Input v-model="value" maxlength="30" placeholder="arama..." @keyup.enter.native="search"/>
    </Section>
</template>

<script>
export default {
    data(){
        return {
            value: '',
        }
    },

    methods: {
      search(){
        this.$emit('search', this.value);
      }
    }
}
</script>

<style scoped>
  .search-section {
    text-align: center;
    margin-top: 40px;
  }

  .search-section >>> .ivu-input {
    width: 600px;
    height: 45px;
    background-color: #222222;
    border: 1px solid #222222;
    font-weight: 400;
    color: #e3e3e3;
  }

  .search-section >>> .ivu-input::placeholder {
    color: rgb(116, 116, 116);
  }
</style>