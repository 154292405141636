<template>
  <div style="height: 100vh; text-align: center; margin-top: 40px;">
      <img src="@/assets/404.png" /> <br/>
      ULAŞMAYA ÇALIŞTIĞINIZ SAYFA BULUNAMADI <br/>
      <Button 
      @click="hasHistory() 
                ? $router.go(-1) 
                : $router.push('/')"
      size="default" style="margin-top: 10px;" type="primary">Geri Dön</Button>
  </div>
</template>

<script>
export default {
  methods: {
    hasHistory() { return window.history.length > 2 }
  }
}
</script>
<style scoped>
img {
  width: 300px;
  opacity: 0.7;
  border: 1px solid #e2e2e2;
}
span {
  font-size: 27px;
  font-weight: 700;
}
</style>