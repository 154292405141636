import Vue from 'vue';
import App from './App.vue';
import ViewUI from 'view-design';
import locale from 'view-design/dist/locale/tr-TR';
import VueGtm from '@gtm-support/vue2-gtm';
import '@/assets/less/theme.less';
import router from '@/route/';
import VueSweetalert2 from 'vue-sweetalert2';
import store from './store/index';
import 'sweetalert2/dist/sweetalert2.min.css';
import moment from 'moment';
import 'moment/locale/tr';
import SocketIO from 'socket.io-client';
import VueSocketIO from 'vue-socket.io';
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'


const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

// Layout
import Admin from '@/layouts/admin';
import Login from '@/layouts/login';
import Default from '@/layouts/default';
import Frontend from '@/layouts/frontend';

const socketInstance = SocketIO(process.env.VUE_APP_API_URL, {
  transports: ['websocket']
});

Vue.use(VueGtm, {
  id: 'GTM-N4NX6VD',
  defer: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});

Vue.use(new VueSocketIO({
  debug: false,
  connection: socketInstance, //options object is Optional
  vuex: {
    store,
    actionPrefix: "SOCKET_",
    mutationPrefix: "SOCKET_",
  }
}));

Vue.use(ViewUI, {locale});
Vue.use(VueSweetalert2, options);
Vue.use(require('vue-moment'), {moment});
Vue.use(VuePlyr, { plyr: {}});
Vue.use(require('vue-cookies'));
Vue.component('Admin', Admin);
Vue.component('Login', Login);
Vue.component('Default', Default);
Vue.component('Frontend', Frontend);


Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
