<template>
    <Tabs value="name1" class="tab-wrapper">
        <TabPane label="Giriş Yap" name="name1">
            <Form :model="login" ref="formInline" class="formLogin" :rules=rules>
                <FormItem label="TC No veya E-Posta" prop="tcno">
                    <Input v-model="login.tcno" prefix="md-person" size="large" class="s-input" maxlength="11"/>
                </FormItem>
                <FormItem label="Şifre" prop="password">
                    <Input type="password" size="large" prefix="md-lock" password v-model="login.password"/>
                </FormItem>
                <FormItem>
                    <Button type="primary" style="font-weight: 700" @click="handleSubmit('formInline')" long>GİRİŞ YAP</Button>
                </FormItem>
            </Form>
        </TabPane>
        <TabPane label="Şifremi Unuttum" name="name2">
            <Form :model="login" ref="forgotPassword" :rules=rulesPassword class="formLogin">
                <FormItem label="E-Posta" prop="email">
                    <Input v-model="login.email" prefix="md-mail" size="large"/>
                </FormItem> 
                <FormItem>
                    <Button type="primary" @click="handleForgotPassword('forgotPassword')" long>Gönder</Button>
                </FormItem>
            </Form>
        </TabPane>
    </Tabs>
</template>

<script>
import { get } from '@/api';
export default {
    data() {
        const validatePass = (rule, value, callback) => {
            if (value === undefined || value === '') {
                callback(new Error('Bu alan boş bırakılamaz!'));
            } else {
                callback();
            }
        };
        return {
            login: {},
            rules : {
                tcno: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                password: [
                    { validator: validatePass, trigger: 'blur' }
                ],
            },
            rulesPassword: {
                email: [
                    { validator: validatePass, trigger: 'blur' }
                ],
            }
        }
    },

    methods: {
        handleSubmit(name) {
            this.$refs[name].validate(async (valid) => {
                if (valid) {
                    const r = await get('/users/login', this.login);
                    if(r.success && r.message.success) {
                        sessionStorage.setItem('token', r.message.data.token);
                        await this.$store.dispatch('users/setUser', r.message.data.user);
                        this.$router.push('/');
                        // this.$swal('Kayıt Başarılı!');
                    } else {
                        this.$swal('Uyarı', r.error.response.data.error);
                    }
                } else {
                    this.$Message.error('Fail!');
                }
            });
        },

        handleForgotPassword(name) {
            this.$refs[name].validate(async (valid) => {
                if (valid) {
                    const result = await get('/users/forgotpassword', {email: this.login.email});
                    if(result.success) {
                        this.$swal('', 'Giriş bilgileriniz sistemde kayıtlı e-posta adresinize gönderilmiştir. Spam, Gereksiz E-Posta\'lar gibi klasörlerinizi de kontrol ediniz!');
                    } else{
                        this.$swal('', result.error.response.data.error);
                    }
                } else {
                    this.$Message.error('Fail!');
                }
            });
        }
    },
};
</script>

<style scoped>
.formLogin >>> .ivu-input {
    background-color: #353535 !important;
    border: 1px solid #403f3f !important;
    color: #a2a2a2 !important;
}

.formLogin >>> .ivu-form-item-label {
    color: #7d7d7d !important;
}
</style>