<template>
  <Row :gutter="gutter">
    <i-col span="16">
      <Form :model="form" :label-width="130" ref="formInline" :rules="rules">
        <FormItem label="Duyuru" prop="name">
            <Input v-model="form.name" type="textarea" :rows="4" />
        </FormItem>
        <FormItem label="Başlangıç Tarihi" prop="startdate">
            <DatePicker type="date" v-model="form.startdate" style="width: 200px"></DatePicker>
        </FormItem>
        <FormItem label="Bitiş Tarihi" prop="enddate">
            <DatePicker type="date" v-model="form.enddate" style="width: 200px"></DatePicker>
        </FormItem>
        <FormItem>
          <Checkbox v-model="form.status">Aktif</Checkbox>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="handleSubmit('formInline')">Kaydet</Button>
            <Button style="margin-left: 8px" to="/admin/notifications">Kapat</Button>
        </FormItem>
      </Form>
    </i-col>
    <i-col span="8">
    </i-col>
  </Row>
</template>

<script>
import { post } from '@/api';
// const validateNumber = (rule, value, callback) => {
//     if (!value) {
//         return callback(new Error('Bu alan boş bırakılamaz!'));
//     }
//     if (!Number.isInteger(value)) {
//         callback(new Error('Sadece rakamlardan oluşmalıdır!'));
//     } else { callback(); }
// };

export default {
  data() {
    return {
      gutter: 15,
      form: {},
      rules: {
          // tcno: [
          //     { validator: validateNumber, trigger: 'blur' }
          // ],
          // firstname: [
          //     { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
          // ],
          // surname: [
          //     { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
          // ],
          // email: [
          //     { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
          // ]
      }
    }
  },
  methods: {
    handleSubmit(name) {
        this.$refs[name].validate(async (valid) => {
            if (valid) {
              const r = await post('/notifications/add', this.form);
              if(r.success) {
                this.$swal('Kayıt Başarılı!');
              } else {
                this.$swal(r.error.response.data.error);
              }
            } else {
                this.$Message.error('Fail!');
            }
        });
    },
  }

}
</script>