const pages = [
    {key: 'categories', value: 'Kategoriler'},
    {key: 'users', value: 'Kullanıcılar'},
    {key: 'videos', value: 'Videolar'},
    {key: 'notifications', value: 'Duyurular'},
    {key: 'questions', value: 'Sorular'},
];

const userTypes = [
    {key: 1, value: 'Admin'},
    {key: 0, value: 'Kullanıcı'},
]

module.exports= {
    pages,
    userTypes
} 