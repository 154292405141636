<template>
<div>
    <div style="display: flex; align-items: center; justify-content: space-between;">
        <div style="display: flex; align-items: center; justify-content: center;">
            <Input v-model="search" placeholder="TC NO'ya göre arama..." style="width: 200px; margin-bottom: 5px;" />
            <Button type="success" size="small" style="margin-left: 10px; margin-bottom: 4px;" @click="searchByID(search)">Ara</Button>
        </div>
        <div v-if="allUsers.total">
            <Page :total="allUsers.total" :page-size="30"  size="small" @on-change="changePage" />
        </div>
    </div>
    <Table border :columns="columns" :data="allUsers.result" v-if="allUsers">
        <template slot-scope="{ row }" slot="action">
            <Button type="primary" size="small" style="margin-right: 5px" @click="edit(row._id)">Düzenle</Button>
            <Button type="error" size="small" @click="remove(row._id)">Sil</Button>
        </template>
    </Table>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            columns: [
                {
                    title: 'TC No',
                    key: 'tcno',
                },
                {
                    title: 'İsim',
                    key: 'firstname'
                },
                {
                    title: 'Soyisim',
                    key: 'surname'
                },
                {
                    title: 'Özellikler',
                    slot: 'action',
                    width: 150,
                    align: 'center'
                }
            ],
            search: null,
            allUsers: {
                total: null
            },
        }
    },

    methods: {
        edit(x) {
            this.$router.push(`/admin/users/${x}`);
        },

        changePage(page) {
            this.$store.dispatch('users/getAll', page);
        },

        remove(x) {
            this.$swal({
                title: "İlgili öğrenci sistemden kaldırılacaktır",
                text: "Bunu yapmak istediğinize emin misiniz?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Evet",
                cancelButtonText: "Hayır",
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch('users/removeUser', x);
                }
            });
        },

        searchByID(tcno) {
            this.$store.dispatch('users/searchUser', tcno);
        }
    },

    watch: {
        users() {
            this.allUsers = this.users;
        },

        search() {
            if(this.search === '') {
                this.$store.dispatch('users/getAll');
            }
        }
    },

    mounted() {
        this.$store.dispatch('users/getAll');
        this.allUsers = this.users;
    },

    computed: {
        ...mapGetters({
            users: 'users/getUsers',
        }),
    }
}
</script>

<style>
    .ivu-page-item-active > a {
        color: #D53C1B;
        text-decoration: underline;
    }
</style>