<template>
      <Modal
        v-model="showModal"
        ok-text="Tamam"
        cancel-text=""
        title="Kategori İzinleri"
        >
        <div class="video-list">
            <ul>
                <li v-for="(item) in categoriesModal" :key="item._id">
                    <ul>
                        <li>{{ item.name }}</li>
                        <li><InputNumber v-model="item.value" :value=defaultValue :min="0" :max="300"></InputNumber></li>
                        <li>
                            <date-picker
                                type="date"
                                v-model="item.expire_date"
                                >
                            </date-picker>
                        </li>
                        <li><Button type="primary" icon="md-add" @click="handleValues(item._id, item.value, item.expire_date)" size="small"></Button></li>
                        <li><Button type="primary" icon="md-trash" @click="removeValues(item._id)" size="small"></Button></li>
                    </ul>
                </li>
            </ul>
        </div>
    </Modal>
</template>

<script>
export default {
    props: {
        categoryShow: {
            type: Boolean
        },
        user: {
            type: String
        },
        lectures: {
            type: Array
        },
        categories: {
            type: Array
        }
    },
    data() {
        return {
            user_id: null,
            data: [],
            defaultValue: 0,
            categoriesModal: null
        }
    },

    methods: {
        async handleValues(category_id, value, expire_date) {
            if(!expire_date || !value) {
                this.$swal('', 'Lütfen, bitiş tarihini ve/veya değeri boş bırakmayınız!');
            } else {
                this.$swal({
                    title: "Kategori kullanıcıya hak olarak verilecektir",
                    text: "Bunu yapmak istediğinize emin misiniz?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Evet",
                    cancelButtonText: "Hayır",
                }).then((v) => {
                    if(v.value) {
                        this.$emit('setLecture', {category_id, value, expire_date});
                    }
                });
            }
        },
        async removeValues(category_id) {
            this.$swal({
                title: "Kategori ve kategoriye bağlı kullanıcı hakları sistemden kaldırılacaktır",
                text: "Bunu yapmak istediğinize emin misiniz?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Evet",
                cancelButtonText: "Hayır",
            }).then((v) => {
                if(v.value) {
                    this.$emit('removeLecture', {category_id});
                }
            });
        },
    },

    computed: {
        showModal:{
            get(){return this.categoryShow},
            set(v){ this.$emit("isModalCategory", v)}
        }
    },

    watch: {
        showModal() {
            this.categoriesModal = this.categories;
        }
    }
}

</script>

<style scoped>
.video-list >>> .ivu-input-number {
    width: 57px;
    font-weight: 700;
    float: right;
}

.video-list >>> .ivu-date-picker {
    width: 130px;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 140px;
    font-size: 14px;
}

.video-list ul {
    display: flex;
    list-style: none;
    padding-left: 10px;
    flex-direction: column;
    justify-content: center;
}

.video-list ul li {
    padding: 5px;
    background-color: #f1f1f1;
    margin-bottom: 5px;
}

.video-list ul li ul {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 0;
}

.video-list ul li ul li {
    flex: 3
}

.video-list ul li ul li:nth-child(1) {
    flex: 3 1 100%;
}

.video-list ul li span{
    margin-top: 6px;
    display: inline-block;
}

.video-list ul li input {
    margin-right: auto;
    float: right;
}
</style>