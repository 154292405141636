const state = () => ({
    error: {
        active: false,
        message: null,
    }
});

const actions = {
    async setError({commit}, data) {
       commit('ADD', data);
    },

    async removeError({commit}) {
        commit('REMOVE');
    }
}

const mutations = {
    ADD(state, data) {
        state.error.message = data;
        state.error.active = true;
    },
    REMOVE(state) {
        state.error.message = null;
        state.error.active = false;
    }
}

const getters = {
    getError(state) {
        return state.error;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}