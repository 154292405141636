<template>
      <Modal
        v-model="showModal"
        ok-text="Kaydet"
        cancel-text="İptal"
        @on-ok="handleValues"
        title="Video İzinleri"
        >
        <div class="video-list">
            <ul v-for="(category) in data" :key="category._id">
                <li>{{ category[0].category_id.name }}</li>
                <li v-for="item in category" :key="item._id">
                    <span>{{ item.video_id.name }} <br/>
                    <small>{{ item.video_id.subject }} </small>
                    </span>
                    <InputNumber v-model="item.remain" :min="0" :max="30"></InputNumber>
                </li>
            </ul>
            
        </div>
    </Modal>
</template>

<script>
import { get, purePost } from '@/api';
export default {
    props: {
        videoShow: {
            type: Boolean
        },
        user: {
            type: String
        },
    },
    data() {
        return {
            user_id: null,
            data: [],
        }
    },

    methods: {
        async getData() {
            this.user_id = this.user;
            const result = await get(`/rights/${this.user_id}`);
            if(result.success) this.data = result.message.data;
        },
        async handleValues() {
            const data = this.data.flat();
            const final = data.map((v) => {
                            return {user_id: v.user_id, video_id: v.video_id._id, remain: v.remain}
                          });
            await purePost('/rights/update', final);
        }
    },

    computed: {
        showModal:{
            get(){return this.videoShow},
            set(v){ this.$emit("isModalVideo", v)}
        }
    },

    watch: {
        showModal: function(n) {
            n && this.getData();
        }
    }
}

</script>

<style scoped>
.video-list {
    height: 350px;
    overflow-x: auto;
}

.video-list >>> .ivu-input {
    width: 57px;
}

.video-list >>> .ivu-input-number {
    float: right;
} 

.video-list ul {
    display: flex;
    list-style: none;
    padding-left: 10px;
    flex-direction: column;
    justify-content: center;
}

.video-list ul li {
    padding: 5px;
    background-color: #f1f1f1;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    font-weight: 700;
}

.video-list ul li span{
    margin-top: 6px;
    display: inline-block;
    font-weight: 500;
    flex: 1;
    margin-left: 10px;
}

.video-list ul li span small{
    font-weight: 500;
}

.video-list ul li input {
    margin-right: auto;
    float: right;
}
</style>