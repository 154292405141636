<template>
  <Layout>
    <Header></Header>
    <Menu></Menu>
    <SearchBar @search="handleSearch"></SearchBar>
    <Button @click="openLive()" type="error" size="default" class="live-button" v-if="live"><Icon type="md-pulse" class="animate-flicker" /> {{ live.name }}</Button>
    <transition name="fade" mode="out-in" :duration="{ enter: 500, leave: 800 }">
      <Section class="body-section" v-if="searchResult">
        <h5 class="search-text">
          Arama Sonuçları
          <div class="list-icon" style="top: -10px;">
            <Tooltip content="Arama Sonuçlarını Kapat" placement="left-start">
              <Icon type="md-close" @click="clearSearch"/>
            </Tooltip>
          </div>
          </h5>
        <carousel :perPage=4 :paginationEnabled="false">
          <slide v-for="(item, i) in searchResult"
                  :key="item._id"
                  :data-index="i"
                  :data-live="item.live"
                  :data-start="item.startdate"
                  :data-id="item._id"
                  :data-category="item.category._id"
                  @slideclick="handleSlideClick"
          >
            <div class="card">
              <div class="card-body">
                <div class="card-budget" v-if="item.highlight">{{ item.highlight }}</div>
                <div class="gradient" @mouseover="handleHover('search',i)" @mouseleave="handleHover('search',i)"></div>
                <div class="card-image" v-if="env === 'development'" :ref="`element-search-${i}`" :style="item.cover && { backgroundImage: 'url('+require('@/assets/no-image.png')+')' }"></div>
                <div class="card-image" else :ref="`element-search-${i}`" :style="item.cover ? { backgroundImage: 'url('+upload_url+item.cover+')' } : { backgroundImage: 'url('+require('@/assets/no-image.png')+')' }"></div>
                <div class="live-date" v-if="item.live">
                  <strong>Tarih</strong> <br/> {{ item.startdate | moment("D.M.YYYY - H:mm") }}
                </div>
                <div class="card-description" v-else v-html="item.subject"></div>
                <div class="card-time" v-if="!item.live">{{ item.duration }}</div>
              </div>
              <div class="card-footer">
                <div class="footer-name">{{ item.name }}</div>
                <div class="footer-live" v-if="item.live"><Icon type="md-pulse" class="animate-flicker" /></div>
              </div>
            </div>
          </slide>
        </carousel>
      </Section>
    </transition>
    <Section class="body-section" v-for="(category, c) in videos" :key="category._id">
      <div class="section-header" :style="randomBorder(c)">
        {{ category.name }}
        <span>videoları</span>
        <div class="list-icon">
          <Tooltip content="Kategorinin tamamı" placement="left-start">
            <Icon type="md-list" @click="getVideoByCategory(category._id)" />
          </Tooltip>  
        </div>
      </div>
      <carousel :perPage=4 :paginationEnabled="false">
        <slide v-for="(item, i) in category.videos" 
                :key="item._id"
                :data-index="i"
                :data-live="item.live"
                :data-start="item.startdate"
                :data-id="item._id"
                :data-category="category._id"
                @slideclick="handleSlideClick"
        >
          <div class="card">
            <div class="card-body">
              <div class="card-budget" v-if="item.highlight">{{ item.highlight }}</div>
              <div class="gradient" @mouseover="handleHover(c,i)" @mouseleave="handleHover(c,i)"></div>
              <div class="card-image" v-if="env === 'development'" :ref="`element-${c}-${i}`" :style="{ backgroundImage: 'url('+require('@/assets/no-image.png')+')' }"></div>
              <div class="card-image" else :ref="`element-${c}-${i}`" :style="item.cover ? { backgroundImage: 'url('+upload_url+item.cover+')' } : { backgroundImage: 'url('+require('@/assets/no-image.png')+')' }"></div>
              <div class="live-date" v-if="item.live">
                <strong>Tarih</strong> <br/> {{ item.startdate | moment("D.M.YYYY - H:mm") }}
              </div>
              <div class="card-description" v-else v-html="item.subject"></div>
              <div class="card-time" v-if="!item.live">{{ item.duration }}</div>
            </div>
            <div class="card-footer">
              <div class="footer-name">{{ item.name }}</div>
              <div class="footer-live" v-if="item.live"><Icon type="md-pulse" class="animate-flicker" /></div>
            </div>
          </div>
        </slide>
      </carousel>
    </Section>
  </Layout>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import Header from '@/components/frontend/Header';
import Menu from '@/components/frontend/Menu';
import SearchBar from '@/components/frontend/SearchBar';
import { get } from '@/api';
import { mapGetters } from 'vuex';
export default {
  components: { Carousel, Slide, Header, Menu, SearchBar },
  data() {
    return {
      upload_url: process.env.VUE_APP_UPLOAD_PATH,
      env: process.env.NODE_ENV,
      colors: ['#58bb25', '#258bbb', '#9625bb', '#bb2578', '#bb8d25'],
      carouselOptions: {
        perPage: 4,
        category: null,
        paginationEnabled: false
      },
      videos: null,
      searchResult: null,
    }
  },

  methods: {
    handleSlideClick(data) {
      if(data.id) {
        if(data.live) {
          if(data.start && this.$moment(data.start).valueOf() < Date.now()) {
            this.$router.push(`/video/${data.id}/${data.category}`);
          } else {
            this.$swal(`Canlı yayın ${this.$moment(data.start).format('D.M.YYYY - H:mm')} tarihinde başlayacaktır!`);
          }
        } else {
          this.$router.push(`/video/${data.id}/${data.category}`);
        }

      }
    },

    async handleSearch(value){
      if(value){
        const result = await get('/videos/search', {value: value});
        if(result && result.success) {
          this.searchResult = result.message.data;
        } else {
          this.$swal('', result.error.response.data.error);
        }
      }
    },

    openLive() {
     this.$router.push(`/video/${this.live._id}/${this.live.category}`); 
    },

    clearSearch() {
      this.searchResult = null;
    },

    getVideoByCategory(category) {
      this.$router.push(`/getvideobycategory/${category}`);
    },

    handleHover(c,i){
      const element = this.$refs[`element-${c}-${i}`];
      if(element[0].className.includes('scale')) {
        element[0].classList.remove('scale');
      } else {
        element[0].classList.add('scale');
      }
    },

    randomBorder(i) {return { borderLeft: '3px solid' + this.colors[i] }}
  },

  async mounted() {
    const result = await get('/videos');
    await this.$store.dispatch('users/getLectures', this.$store.state.users.user._id);
    await this.$store.dispatch('users/getLive', {lectures: this.lecture});
    if(result.success) {
      this.videos = result.message.data;
      if(!this.isReadNotification) {
        await this.$store.dispatch('notifications/getLastActive');
        if(this.notification) {
          this.$swal('', this.notification.name, 'info').then(() => {
            this.$store.dispatch('notifications/setRead', true);
          });
        }
      }
    } else {
      this.$swal(result.error.response.data.error);
    }
  },

  computed: {
    ...mapGetters({
      notification: 'notifications/getNotification',
      isReadNotification: 'notifications/getReadable',
      lecture: 'users/getLecture',
      live: 'users/getLive',
    })
  }
}
</script>

<style scoped>
  .body-section {
    margin: 43px auto;
    max-width: 1190px;
    position: relative;
  }

  .body-section .list-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 30px;
    color: #FFFFFF;
    cursor: pointer;
  }

  .body-section .card {
    width: 288px;
    height: 420px;
    margin: 5px;
    position: relative;
    cursor: pointer;
  }

  .body-section .card .card-body {
    width: 288px;
    height: 388px;
    position: relative;
    overflow: hidden;
  }

  .body-section .card .card-body .card-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform .5s;
    overflow: hidden;
  }

  .scale {
    transform: scale(1.2);
  }


  .body-section .card .card-body .card-budget {
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #ae3620;
    min-width: 80px;
    height: 25px;
    padding: 0px 5px;
    word-wrap: break-word;
    color: #FFF;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    line-height: 24px;
    z-index: 11;
  }

  .body-section .card .card-body .gradient {
    background: rgb(20,20,20);
    background: -moz-linear-gradient(0deg, rgba(20,20,20,1) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(20,20,20,1) 0%, rgba(0,0,0,0) 100%);
    background: linear-gradient(0deg, rgba(20,20,20,1) 0%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#141414",endColorstr="#000000",GradientType=1);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
  }

  .body-section .card .card-body .card-description {
    position: absolute;
    width: 100%;
    bottom: 20px;
    font-size: 13px;
    left: 5px;
    color: #e3e3e3;
    white-space: pre-wrap;
    word-wrap: break-word;
    z-index: 11;
  }

  .body-section .section-header {
    position: relative;
    height: 50px;
    vertical-align: center;
    font-size: 21px;
    margin-left: 5px;
    color: #dfdfdf;
    padding-left: 7px;
  }

  .body-section .section-header span{
    display: block;
    font-size: 14px;
    margin-top: -5px;
    color: #9e9e9e;
  }

  .live-button {
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 15px;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .live-date {
    position: absolute;
    width: 100%;
    bottom: 20px;
    font-size: 13px;
    left: 5px;
    color: #e3e3e3;
    white-space: pre-wrap;
    word-wrap: break-word;
    z-index: 11;
  }

  .body-section .card .card-body .card-time {
    position: absolute;
    bottom: 0px;
    right: 5px;
    font-size: 12px;
    color: #e3e3e3;
    white-space: pre-wrap;
    word-wrap: break-word;
    z-index: 11;
  }

  .body-section .card .card-footer {
    height: 37px;
    padding: 4px;
    display: flex;
    align-items: center;
    background-color: #222222;
    border-bottom: 1px solid #2f2f2f;
    border-left: 1px solid #2f2f2f;
    border-right: 1px solid #2f2f2f;
    color: #e3e3e3;
    font-size: 16px;
    font-weight: 700;
  }

  .search-text {
    display: block;
    border-bottom: 1px solid #545454;
    font-size: 22px;
  }

  .footer-name {
    flex: 5;
  }
  
  .footer-live {
    flex: 1;
    text-align: right;
  }

  .footer-live i {
    color: #ae3620;
    font-size: 23px;
  }

  @keyframes flickerAnimation { /* flame pulses */
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  .animate-flicker {
      opacity:1;
      animation: flickerAnimation 2s infinite;
  }

</style>