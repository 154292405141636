<template>
    <Layout :style="{minHeight: '100vh'}">
        <Sider hide-trigger :style="{position: 'fixed', minHeight: '100vh', marginTop: '60px'}">
        <Navigation />
        </Sider>
        <Layout>
        <Header :style="{position: 'fixed', width: '100%', zIndex: 10}">
            <Row type="flex" justify="end">
                <i-col>
                    <Button size="default" type="dashed" ghost @click="logout">Çıkış</Button>
                </i-col>
            </Row>
        </Header>
        <Content :style="{margin: '88px 20px 10px 220px', background: '#fff', minHeight: '500px', padding: '20px'}">
            <slot />
        </Content>
        </Layout>
    </Layout>
</template>

<script>
import Navigation from '@/components/Navigation';
import { mapGetters } from 'vuex';
export default {
    name: 'App',
    components: { Navigation },
    computed: {
        ...mapGetters({
            getError: 'error/getError',
        }),
    },
    // TODO: incele burayı deep nedir?
    watch: {
        getError: {
            handler: function(to) {
                if(to.active) {
                    this.$swal(to.message);
                }
            },
            deep: true
        }
    },

    methods: {
        logout(){
            this.$store.dispatch('users/logoutUser');
            this.$router.push('/login');
        }
    }
}
</script>

<style>
    body {
        background-color: #FFFFFF;
    }
</style>