<template>
  <Row :gutter="gutter">
    <i-col span="16">
      <Form :model="form" :label-width="130" ref="formInline" :rules="rules">
        <FormItem label="TC No" prop="tcno">
            <Input v-model="form.tcno" maxlength="11"/>
        </FormItem>
        <FormItem label="İsim" prop="firstname">
            <Input v-model="form.firstname"/>
        </FormItem>
        <FormItem label="Soyisim" prop="surname">
            <Input v-model="form.surname"/>
        </FormItem>
        <FormItem label="E-Posta" prop="email">
            <Input v-model="form.email"/>
        </FormItem>
        <FormItem label="Telefon" prop="phone">
            <Input v-model="form.phone" v-mask="'0### ### ## ##'" masked="false"/>
        </FormItem>
        <FormItem label="Password" prop="password">
            <Input v-model="form.password" type="password" password/>
            <Button size="small" @click="createAPassword(5)">Şifre Üret</Button>
        </FormItem>
        <FormItem label="Bitiş Tarihi" prop="expire_date">
            <DatePicker type="date" v-model="form.expire_date" style="width: 200px"></DatePicker>
        </FormItem>
        <FormItem label="İzinler" prop="authority">
            <Select v-model="form.authority" style="width:200px">
                <Option v-for="item in userTypes" :value="item.key" :key="item.key">{{ item.value }}</Option>
            </Select>
        </FormItem>
        <FormItem label="Haklar" prop="permission" v-if="form.authority === 1">
            <Select v-model="form.permission" multiple style="width:200px">
                <Option v-for="item in pages" :value="item.key" :key="item.key">{{ item.value }}</Option>
            </Select>
        </FormItem>
        <FormItem>
          <Checkbox v-model="form.status">Aktif</Checkbox>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="handleSubmit('formInline')">Kaydet</Button>
            <Button style="margin-left: 8px" to="/admin/users">Kapat</Button>
        </FormItem>
      </Form>
    </i-col>
    <i-col span="8">
    </i-col>
  </Row>
</template>

<script>
import { post } from '@/api';
import { mask } from 'vue-the-mask'
import { pages, userTypes } from '@/helpers/constants';
const validateNumber = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('Bu alan boş bırakılamaz!'));
    }
    if (!Number.isInteger(parseInt(value))) {
        callback(new Error('Sadece rakamlardan oluşmalıdır!'));
    } else { callback(); }
};

export default {
  directives: { mask },
  data() {
    return {
      gutter: 15,
      form: {
        authority: 0,
        password: null,
      },
      pages,
      userTypes,
      rules: {
          tcno: [
              { validator: validateNumber, trigger: 'blur' }
          ],
          firstname: [
              { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
          ],
          surname: [
              { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
          ],
          email: [
              { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
          ],
          password: [
              { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
          ]
      }
    }
  },
  methods: {
      handleSubmit(name) {
          this.$refs[name].validate(async (valid) => {
              if (valid) {
                const r = await post('/users/addfromadmin', this.form);
                if(r.success) {
                  this.$swal('Kayıt Başarılı!');
                } else {
                  this.$swal(r.error.response.data.error);
                }
              } else {
                  this.$Message.error('Hata!');
              }
          });
      },

      createAPassword(length) {
        const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
        const charactersLength = characters.length;
        let result = '';
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.form.password = result;
      }
  }

}
</script>