import { get, post } from '@/api';
const state = () => ({
    lectures: [],
    categories: []
});

const actions = {

    async getLecturesByUser({commit}, user_id) {
        const r = await get(`/lectures/getlecturesbyuser/${user_id}`);
        if(r.success) {
            commit('ADD_LECTURES', r.message.data);
        } else {
            commit('CLEAR_LECTURES');
        }
    },

    //eslint-disable-next-line
    async setLecturesByUser({commit}, data) {
        const r = await post('/lectures/add', data);
        if(r && r.success && r.message.success) {
            commit('UPDATE_LECTURES', r.message.data)
        }
    },

    async getCategories({commit}) {
        const r = await get(`/lectures/getcategories`);
        if(r.success) commit('ADD_CATEGORIES', r.message.data);
    },

    async removeLectureByUser({commit}, data) {
        const r = await post('/lectures/remove', data);
        if(r && r.success) commit('REMOVE_LECTURES', data);
    }
}

const mutations = {
    ADD_LECTURES(state, data) {
        state.lectures = data;
    },

    UPDATE_LECTURES(state, data) {
        const a = state.lectures.map(v => v.category_id).indexOf(data.category_id);
        if(a > -1) {
            state.lectures[a].expire_date = data.expire_date;
            state.lectures[a].value = data.value;
        } else {
            state.lectures.push(data);
        }
    },

    CLEAR_LECTURES(state) {
        state.lectures = [];
    },

    REMOVE_LECTURES(state, data) {
        const l = state.lectures.map(v => v.category_id).indexOf(data.category_id);
        const c = state.categories.map(v => v._id).indexOf(data.category_id);
        if(l > -1) state.lectures.splice(l, 1);
        if(c > -1) {
            state.categories[c].value = 0;
            state.categories[c].expire_date = null;
        }
    },
    
    ADD_CATEGORIES(state, data) {
        state.categories = data;
    },
}

const getters = {
    getLectures(state) {
        return state.lectures;
    },
    getCategories(state) {
        return state.categories;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}