<template>
    <Table border :columns="columns" :data="categories">
        <template slot-scope="{ row }" slot="action">
            <Button type="primary" size="small" style="margin-right: 5px" @click="edit(row._id)">Düzenle</Button>
            <Button type="error" size="small" @click="remove(row._id)">Sil</Button>
        </template>
    </Table>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            columns: [
                {
                    title: 'Kategori Adı',
                    key: 'name'
                },
                {
                    title: 'Türü',
                    key: 'type',
                    render: (h, {row}) => {
                        if(row.type) return h('span', row.type.name);
                    },
                },
                {
                    title: 'Alt Türü',
                    key: 'subtype',
                    render: (h, {row}) => {
                        if(row.subtype) return h('span', row.subtype.name);
                    },
                },
                {
                    title: 'Başlangıç Tarihi',
                    key: 'startdate',
                    render: (h, {row}) => {
                        if(row.startdate) return h('span', this.$moment(row.startdate).format('DD.MM.YYYY'));
                    },
                },
                {
                    title: 'Bitiş Tarihi',
                    key: 'enddate',
                    render: (h, {row}) => {
                        if(row.enddate) return h('span', this.$moment(row.enddate).format('DD.MM.YYYY'));
                    },
                },
                {
                    title: 'Özellikler',
                    slot: 'action',
                    width: 150,
                    align: 'center'
                }
            ],
        }
    },

    methods: {
        edit(x) {
            this.$router.push(`/admin/categories/${x}`);
        },

        remove(x) {
            this.$swal({
                title: "Emin misin?",
                text: "İlgili kategori ve o kategoriye bağlı videolar sistemden kaldırılacaktır",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Evet",
                cancelButtonText: "Hayır",
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch('categories/removeUser', x);
                }
            });
        }
    },

    mounted() {
        this.$store.dispatch('categories/getAll');
    },

    computed: {
        ...mapGetters({
            categories: 'categories/getCategories',
        }),
    }
}
</script>