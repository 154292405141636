<template>
  <Layout>
    <Header></Header>
    <Section class="video-section">
      <Row :gutter="15">
        <i-col>
          <div class="video-header">
            <Button
              icon="ios-arrow-back"
              @click="$router.go(-1)"
              type="error"
              size="small"
              class="come-back-button"
              >Geri Dön</Button
            >
            <div class="come-back"></div>
            <hr />
          </div>
        </i-col>
        <i-col :span="videoWide">
          <div class="skeleton" v-if="skeleton"></div>
          <div ref="videoBox" style="width: 100%; height: 100%; display: block; position: relative;" v-show="remain">
            <div class="wide-button">
                <Tooltip :content="wide?'Küçült':'Genişlet'" placement="top-end">
                  <Button type="error" size="small" @click="videoSize"><Icon type="md-code" /></Button>
                </Tooltip>
            </div>
            <div class="waterMark" :class="{fadeIn: toggleWaterMark}" v-bind:style="setRandomSize">{{ id }}</div>
            <div v-if="video && video.isBunny">
              <video style="--plyr-color-main: #690808;" ref="videoPlyr" class="embed-responsive embed-responsive-16by9"></video>              
            </div>
            <div v-else-if="video && video.live" id="vimeo" style="display: block;position: relative;padding: 56.25% 0 0 0;position:relative;"></div>
            <div v-else-if="video && !video.live" id="vimeo"></div>
          </div>
        </i-col>
        <i-col span="8" v-if="!wide">
          <dl class="video-information" v-if="video && !video.live">
            <dt>İzleme Hakkınız</dt>
            <dd>{{ right }}</dd>
            <dt>Konu</dt>
            <dd>{{ video.name }}</dd>
            <dt>Açıklama</dt>
            <dd>{{ video.subject }}</dd>
            <dt>Süre</dt>
            <dd>{{ calculateDuration }}</dd>
          </dl>
          <Form class="notice-form" v-if="video && !video.live">
            <FormItem label="Notlar" prop="notices">
              <Input
                type="textarea"
                v-model="notice.name"
                :rows="6"
                maxlength="240"
                show-word-limit
              />
              <div class="notice-help">
                Bu video ile ilgili, daha sonra hatırlamanızı sağlayacak, sadece
                kendinizin görebileceği özel notlar alabilirsiniz...
              </div>
              <Button
                type="primary"
                ghost
                size="small"
                style="float: right; margin-top: 10px"
                @click="handleNotice"
                >Kaydet</Button
              >
            </FormItem>
          </Form>
        </i-col>
      </Row>
    </Section>
  </Layout>
</template>

<script>
import { post } from "@/api";
import { mapGetters } from 'vuex';
import Header from "@/components/frontend/Header";
import Player from '@vimeo/player';
// import VuePlyr from 'vue-plyr';
import Plyr from 'plyr';
import Hls from 'hls.js';
// import { vueVimeoPlayer } from "vue-vimeo-player";
export default {
  components: { Header },
  data() {
    return {
        id: null,
        player: null,
        duration: 0,
        skeleton: true,
        percent: .50,
        calculatedPercent: 0,
        video_id: null,
        category_id: null,
        remain: null,
        remained: false,
        videoBoxHeight: 0,
        notice: {
          video: null,
          user: null,
        },
        videoWide: "16",
        wide: false,
        videoBoxWidth: 0,
        getRandomHeight: 0,
        getRandomWidth: 0,
        watermark_show_time: process.env.VUE_APP_SHOW_TIMES.split(" "),
        watermark_hide_time: process.env.VUE_APP_HIDE_TIMES.split(" "),
        toggleWaterMark: false,
        playerOptions: {
            controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings'],
            speed: { selected: 1, options: [1, 1.25, 1.5, 1.75, 2] },
            settings: ['quality', 'speed'],
            quality: '',
            i18n: {
                restart: 'Baştan',
                rewind: 'Rewind {seektime}s',
                play: 'Oynat',
                pause: 'Duraklat',
                fastForward: 'İleri {seektime}s',
                seek: 'Sardır',
                seekLabel: '{currentTime} of {duration}',
                played: 'Oynadı',
                buffered: 'Yükleniyor',
                currentTime: 'Current time',
                duration: 'Süre',
                volume: 'Ses',
                mute: 'Sesi Kapat',
                unmute: 'Sesi Aç',
                enableCaptions: 'Enable captions',
                disableCaptions: 'Disable captions',
                download: 'Download',
                enterFullscreen: 'Enter fullscreen',
                exitFullscreen: 'Exit fullscreen',
                frameTitle: 'Player for {title}',
                captions: 'Captions',
                settings: 'Settings',
                pip: 'PIP',
                menuBack: 'Go back to previous menu',
                speed: 'Hız',
                normal: 'Normal',
                quality: 'Kalite',
                loop: 'Tekrar Et',
                start: 'Başlangıç',
                end: 'Son',
                all: 'Hepsi',
                reset: 'Reset',
                disabled: 'Disabled',
            },
        }
    };
  },

  async mounted() {
    // this.duration = this.$refs.video.media;
    this.video_id = this.$route.params.id;
    this.category_id = this.$route.params.category;
    this.notice.video = this.video_id;
    this.notice.user = this.$store.state.users.user._id;
    this.id = this.$store.state.users.user.tcno;
    await this.$store.dispatch('users/getVideo', {video_id: this.video_id});
    await this.$store.dispatch('users/getRight', {user_id: this.notice.user, video_id: this.video_id, category_id: this.category_id})
    if(this.video.isBunny) this.skeleton = false;
    // setTimeout(() => {
    //   const videoBox = this.$refs.videoBox;
    //   const iframe = videoBox.querySelector('iframe');
    //   const vid = (iframe.contentDocument) ? iframe.contentDocument : iframe.contentWindow.document;
    //   console.log(vid.querySelector('video'));
    //   this.$refs.videoBox.addEventListener('click', () => {
    //     console.log(vid);
    //   });
    // }, 1000);

    if(this.video.live) this.right = 500;
    if(this.right > 0) {
      if(this.right === 1) this.$swal('Bu videoyu izlemek için son hakkınız, başlamadan önce yeterince vakit ayırmanızı tavsiye ederiz!');
      this.remain = this.right;
      await this.$store.dispatch('users/getNotice', {user_id: this.notice.user, video_id: this.video_id});
      if(this.getnote) {
          this.notice = this.getnote;
      } else {
        this.notice.name = '';
      }
      if(this.video.live) {
        setTimeout(() => {
          const iframeEl = document.querySelector('iframe');
          iframeEl.style.position = 'absolute';
          iframeEl.style.top = 0;
          iframeEl.style.left = 0;
          iframeEl.style.width = '100%';
          iframeEl.style.height = '100%';
        }, 1000);
      }
      if(this.video.isBunny) {
        var config = {
          debug: true,
          xhrSetup: function (xhr) {
            xhr.withCredentials = true; // do send cookie
            xhr.credentials = 'include'; // do send cookie
            // xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
            // xhr.setRequestHeader("Access-Control-Allow-Origin", "http://localhost:8080");
            // xhr.setRequestHeader("Access-Control-Allow-Origin","https://live.drpatoloji.com");
            // xhr.setRequestHeader("Access-Control-Allow-Credentials", "true");
          },
          fetchSetup: function (context, initParams) {
            initParams.credentials = 'include';
            return new Request(context.url, initParams);
          },
        };

        if (Hls.isSupported()) {
          const hls = new Hls(config);
          hls.loadSource(this.video.link);
          hls.attachMedia(this.playerPlyr);
          hls.on(Hls.Events.MANIFEST_PARSED, function () {
            const availableQualities = hls.levels.map((l) => l.height)
            this.playerOptions.quality = {
              default: availableQualities[0],
              options: availableQualities,
              forced: true,        
              onChange: (e) => this.updateQuality(e),
            }
            this.player = new Plyr(this.playerPlyr, this.playerOptions);
            this.player.on('ready', ()=> {
              setTimeout(() => {
                this.duration = this.player.duration;
                this.calculatedPercent = Math.floor(this.duration - (this.duration * this.percent));
              }, 1000);
            });
            this.loadBunny();            
          }.bind(this));
          window.hls = hls;
        }
      } else {
        this.install();
      }
    } else if(this.video === 0) {
      this.$swal('Bu videoyu izleme hakkınız dolmuş!').then(() => {
        this.$router.go(-1);
      });
    } else {
      this.$swal('Bu videoyu izleme yetkisine sahip değilsiniz!').then(() => {
        this.$router.go(-1);
      });
    }
    // const right = await get('/rights/getonebyuser', {user_id: this.notice.user, video_id: this.notice.video, category_id: this.category_id});
    // if(right.success && right.message.data.value > 0) {
    //   this.remain = right.message.data.value;
    //   const notice = await get(`/notices/${this.notice.user}/${this.notice.video}`);
    //   if(notice.success && notice.message.data){
    //     this.notice = notice.message.data;
    //   }
    //   const result = await get(`/videos/${this.video_id}`);
    //   this.video = result.message.data;
    //   this.id = this.$store.state.users.user.tcno;
    //   this.install();
    // } else {
    //   this.$swal('Bu video için izleme hakkınız dolmuş!').then(() => {
    //     this.$router.go(-1);
    //   });
    // }

  },

  methods: {
    loadBunny() {
      this.player.on('play', this.onPlayerPlay);
      this.player.on('pause', this.onPlayerPause);
      this.player.on('timeupdate', () => {
        this.calculateRemainPlyr(this.player.currentTime);
      });
    },
    async install() {
      var options = {
          id: this.video.link,
          responsive: true,
          withCredentials: true
      };
      this.skeleton = false;
      this.player = new Player('vimeo', options);
      this.duration = await this.player.getDuration();
      this.calculatedPercent = Math.floor(this.duration - (this.duration * this.percent));

      this.player.on('play', this.onPlayerPlay);
      this.player.on('pause', this.onPlayerPause);
      if(!this.video.live) {
        this.player.on('timeupdate', this.calculateRemain);
      }
    },

    updateQuality(newQuality) {
        window.hls.levels.forEach((level, levelIndex) => {
          if (level.height === newQuality) {
            window.hls.currentLevel = levelIndex;
          }
        });
      },

    async handleNotice() {
      const result = await post('/notices/add', this.notice);
      if(result.success){
        if(result.message.data._id) this.notice.id = result.message.data._id;
        this.$swal('Notun başarı ile kayıt edildi');
      }
    },

    getRandomInt(value) {
        return Math.floor(Math.random() * value)
    },

    loopWaterMark() {
        this.toggleWater();
    },

    onPlayerPlay(){
        clearTimeout(this.setLoopFirst);
        clearTimeout(this.setLoopSecond);
        this.toggleWater();
    },

    onPlayerPause(){
        clearTimeout(this.setLoopFirst);
        clearTimeout(this.setLoopSecond);
    },

    calculateRemain(v){
      // This function calculates the user's remaining rights.
      v.seconds > this.calculatedPercent && !this.remained
        ? this.subtractRight()
        : '';
    },

    calculateRemainPlyr(v){
      // This function calculates the user's remaining rights.
      v > this.calculatedPercent && !this.remained
        ? this.subtractRight()
        : '';
    },    

    setWaterMarkShowTime() {
        const random = Math.floor(Math.random() * this.watermark_show_time.length);
        return this.watermark_show_time[random] * 1000;
    },
    setWaterMarkHideTime() {
        const random = Math.floor(Math.random() * this.watermark_hide_time.length);
        return this.watermark_hide_time[random];
    },

    subtractRight(){
      this.remained = true;
      this.$store.dispatch('users/subtractRight', {user_id: this.notice.user, video_id: this.video_id, right: this.right});
    },

    toggleWater() {
        this.videoBoxHeight = this.$refs.videoBox.clientHeight - 200;
        this.videoBoxWidth = this.$refs.videoBox.clientWidth - 200;
        this.setLoopFirst = setTimeout(() => {
            this.toggleWaterMark = false;
            this.setLoopSecond = setTimeout(() => {
                                    this.toggleWaterMark = true;
                                    this.getRandomHeight = this.getRandomInt(this.videoBoxHeight);
                                    this.getRandomWidth = this.getRandomInt(this.videoBoxWidth);
                                    this.toggleWater();
                                }, this.setWaterMarkShowTime());
        }, this.setWaterMarkHideTime());
    },

    videoSize(){
      if(this.wide) {
        this.videoWide = "16";
        this.wide = false;
      } else {
        this.videoWide = "24";
        this.wide = true;
      }
    }
  },

  watch: {
      $route() {
      this.getVideo();
      },
      getRandomHeight(n) {
          this.getRandomHeight = n;
      },
      getRandomWidth(n) {
          this.getRandomWidth = n;
      },
  },

  computed: {
    playerPlyr() {
        return this.$refs.videoPlyr;
    },
    setRandomSize: function() {
        return {
            top: this.getRandomHeight + 'px',
            left: this.getRandomWidth + 'px',
            // display: this.toggleWaterMark?'none':'block',
        };
    },
    calculateDuration: function() {
      return this.$moment.utc(this.duration*1000).format('HH:mm:ss');
    },

    ...mapGetters({
      right: 'users/getRight',
      getnote: 'users/getNotice',
      video: 'users/getVideo',
    })
  }


};
</script>

<style scoped>
.video-section {
  margin: 10px 70px;
  position: relative;
}

.notice-form >>> .ivu-form .ivu-form-item-label {
  padding: 0;
}

.notice-form >>> .ivu-input {
  background-color: #151515;
  border: 1px solid #222222;
}

.notice-form >>> .ivu-input-word-count {
  background-color: #151515;
}

.notice-form >>> .notice-help {
  font-size: 13px;
  line-height: 14px;
  margin-top: 4px;
}

.notice-form >>> .ivu-btn {
  font-size: 15px;
}

.video-information {
  font-size: 14px;
  color: #777777;
  font-weight: 400;
}

.video-information dt {
  font-weight: 700;
  margin-top: 5px;
}

.video-header {
  display: block;
  margin-bottom: 10px;
}

.come-back-button {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}

.video-header hr {
  border: 0;
  border-bottom: 1px solid #666666;
}

.waterMark {
    position: absolute;
    top: 0;
    color: #dfdfdf;
    background-color: black;
    z-index: 999999;
    font-size: 30px;
    opacity: 0;
    font-weight: 700;
    letter-spacing: 3px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: visibility 0s linear 300ms, opacity 300ms;
    visibility: 0;
}

.fadeIn {
  visibility: visible;
  opacity: 0.3;
  transition: visibility 0s linear 0s, opacity 300ms;
}

.wide-button {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
}

.wide-button button {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}

.wide-button button i {
  font-size: 17px;
}

.skeleton {
    width: 100%;
    height: 640px;
    animation: pulse-bg 2s infinite;
}

@keyframes pulse-bg {
    0% { background-color: rgb(43, 43, 43); }
    50% { background-color: #535353; }
    100% { background-color: rgb(46, 46, 46); }
}

</style>