<template>
  <Row :gutter="gutter">
    <i-col span="16">
      <Form :model="form" :label-width="130" ref="formInline" :rules="rules">
        <FormItem label="TC No" prop="tcno">
            <Input v-model="form.tcno" maxlength="11"/>
        </FormItem>
        <FormItem label="İsim" prop="firstname">
            <Input v-model="form.firstname"/>
        </FormItem>
        <FormItem label="Soyisim" prop="surname">
            <Input v-model="form.surname"/>
        </FormItem>
        <FormItem label="E-Posta" prop="email">
            <Input v-model="form.email"/>
        </FormItem>
        <FormItem label="Telefon" prop="phone">
            <Input v-model="form.phone" v-mask="'0### ### ## ##'" masked="false"/>
        </FormItem>
        <FormItem label="Password" prop="password">
            <Input v-model="form.password" type="password" password/>
        </FormItem>
        <FormItem label="Bitiş Tarihi" prop="expire_date">
            <DatePicker
                v-model="form.expire_date"
                type="date"
                format="yyyy-MM-dd"
            >
            </DatePicker>
        </FormItem>
        <FormItem label="İzinler" prop="authority">
            <Select v-model="form.authority" style="width:200px">
                <Option v-for="item in userTypes" :value="item.key" :key="item.key">{{ item.value }}</Option>
            </Select>
        </FormItem>
        <FormItem label="Haklar" prop="permission" v-if="form.authority === 1">
            <Select v-model="form.permission" multiple style="width:200px">
                <Option v-for="item in pages" :value="item.key" :key="item.key">{{ item.value }}</Option>
            </Select>
        </FormItem>
        <FormItem>
          <Checkbox v-model="getEvent">Event</Checkbox>
          <div class="help">Eğer kullanıcı sistemde aktif bir şekilde takılı kalmışsa, Event check aktif olur.</div>
        </FormItem>
        <FormItem>
          <Checkbox v-model="form.status">Aktif</Checkbox>
        </FormItem>
        <FormItem>
          <Button type="success" icon="md-add-circle" style="margin-right: 10px;" @click="showModalCategoryRights()">Kategori</Button>
          <Button type="success" icon="md-add-circle" style="margin-right: 10px;" @click="showModalVideoRights()">Video</Button>
          <Button type="success" icon="ios-mail" @click="sendInformationViaEmail()">E-Posta At</Button>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="handleSubmit('formInline')">Kaydet</Button>
            <Button style="margin-left: 8px" to="/admin/users">Kapat</Button>
        </FormItem>
      </Form>
    </i-col>
    <i-col span="8">
    </i-col>
    <videoRights :videoShow="videoShow" :user="sendUserId" @isModalVideo="isModalVideo" />
    <lectureRights
      :categoryShow="categoryShow"
      :lectures="lectures"
      :categories="categories"
      :user="sendUserId"
      @isModalCategory="isModalCategory"
      @setLecture="setLecture"
      @removeLecture="removeLecture"
    />
  </Row>
</template>

<script>
import { get, post } from '@/api';
import { mask } from 'vue-the-mask';
import videoRights from '@/components/videoRights.vue';
import lectureRights from '@/components/lectureRights.vue';
import { pages, userTypes } from '@/helpers/constants';
import { mapGetters } from 'vuex';

const validateNumber = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('Bu alan boş bırakılamaz!'));
    }
    if (!Number.isInteger(parseInt(value))) {
        callback(new Error('Sadece rakamlardan oluşmalıdır!'));
    } else { callback(); }
};

export default {
  components: {videoRights, lectureRights},
  directives: { mask },
  data() {
    return {
      gutter: 15,
      videoShow: false,
      categoryShow: false,
      form: {},
      expire_date: '2021-01-24T21:00:00.000Z',
      pages,
      userTypes,
      rules: {
          tcno: [
              { validator: validateNumber, trigger: 'blur' }
          ],
          firstname: [
              { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
          ],
          surname: [
              { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
          ],
          email: [
              { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
          ],
          password: [
              { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
          ]
      }
    }
  },

  methods: {
      handleSubmit(name) {
          this.$refs[name].validate(async (valid) => {
              if (valid) {
                const r = await post('/users/addFromAdmin', this.form);
                if(r.success) {
                  this.$swal('Kayıt Güncellendi!');
                } else {
                  this.$swal(r.error.response.data.error);
                }
              } else {
                  this.$Message.error('Hata!');
              }
          });
      },

      async sendInformationViaEmail() {
        this.$swal({
          title: "Uyarı!",
          text: "Kişiye, bilgileri e-posta aracılığı ile gönderilecektir. Bunu yapmak istediğinize emin misiniz?",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        }).then(async ({isConfirmed}) => {
          if(isConfirmed) {
            const result = await get(`/users/sendinformationviaemail/${this.$route.params.id}`);
            if(result && result.success) {
              this.$swal('', result.message.data.message);
            }
          }
        });
      },

      isModalCategory(e) {
        this.categoryShow = e;
      },

      isModalVideo(e) {
        this.videoShow = e;
      },

      setLecture(data) {
        this.$store.dispatch('lectures/setLecturesByUser', {...data, user_id: this.$route.params.id});
      },

      removeLecture(data) {
        this.$store.dispatch('lectures/removeLectureByUser', {...data, user_id: this.$route.params.id});
      },

      showModalVideoRights() {
        this.videoShow = true;
      },

      async showModalCategoryRights() {
        await this.setCategoriesForLectureModal();
        this.categoryShow = true;
      },

      addParametertoCategory(id, value, expire_date) {
        this.categories.map((v) => {
          if(v._id === id) {
            v.value = value;
            v.expire_date = new Date(expire_date);
          }
        });
      },

      updateCategoriesByUser(data) {
        data.map((v) => {
          this.addParametertoCategory(v.category_id, parseInt(v.value), v.expire_date);
        });
      },

      async setCategoriesForLectureModal() {
        await this.$store.dispatch('lectures/getLecturesByUser', this.$route.params.id);
        await this.$store.dispatch('lectures/getCategories');
        if(this.categories && this.categories.length) {
          this.categories.map((v) => {
              v.value = 0;
          });
        }
        if(this.lectures && this.lectures.length) {
          this.updateCategoriesByUser(this.lectures);
        }
      }

  },

  async mounted() {
      const {message} = await get(`/users/${this.$route.params.id}`);
      this.form = message.data;
      this.form.expire_date = new Date(this.form.expire_date);
      this.setCategoriesForLectureModal();
  },

  watch: {
      form(n){
          this.form.password = n.password_plain;
      }
  },

  computed: {
      sendUserId() {
        return this.form._id;
      },
      getEvent: {
        get: function() { return this.form.event ? true : false},
        set: function(v) { this.form.event = v ? 1 : 0} 
      },
      ...mapGetters({
        lectures: 'lectures/getLectures',
        categories: 'lectures/getCategories'
      })
  }

}
</script>