import Vue from 'vue';
import Vuex from 'vuex';
import users from './users';
import categories from './categories';
import videos from './videos';
import notifications from './notifications';
import lectures from './lectures';
import questions from './questions';
import error from './error';

Vue.use(Vuex);
Vue.config.devtools = true;

export default new Vuex.Store({
        modules: {
            users,
            categories,
            videos,
            notifications,
            lectures,
            error,
            questions
        },        
});