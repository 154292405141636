import Vue from 'vue';
import Router from 'vue-router';
import index from '@/pages/admin/';
import addUser from '@/pages/admin/users/add';
import editUser from '@/pages/admin/users/edit';
import getUsers from '@/pages/admin/users/index';
import addCategory from '@/pages/admin/categories/add';
import editCategory from '@/pages/admin/categories/edit';
import getCategories from '@/pages/admin/categories/index';
import addVideo from '@/pages/admin/videos/add';
import editVideo from '@/pages/admin/videos/edit';
import getVideos from '@/pages/admin/videos/index';
import addNotification from '@/pages/admin/notifications/add';
import editNotification from '@/pages/admin/notifications/edit';
import getNotifications from '@/pages/admin/notifications/index';
import getQuestions from '@/pages/admin/questions/index';
import login from '@/pages/frontend/login';
import notFound from '@/pages/404';
import mainPage from '@/pages/frontend/index';
import videoPage from '@/pages/frontend/video';
import videoByCategory from '@/pages/frontend/allVideo';
import questions from '@/pages/frontend/questions';
import { get } from '@/api';

import store from '@/store/index';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'MainPage',
            component: mainPage,
            meta: {layout: 'Frontend', title: 'Dashboard', requiresAuth: true, page: 'mainpage'},
        },
        {
            path: '/questions',
            name: 'Question',
            component: questions,
            meta: {layout: 'Frontend', title: 'Dashboard', requiresAuth: true, page: 'question'},
        },
        {
            path: '/video/:id/:category',
            name: 'VideoPage',
            component: videoPage,
            meta: {layout: 'Frontend', title: 'Dashboard', requiresAuth: true, page: 'videopage'},
        },
        {
            path: '/getvideobycategory/:category',
            name: 'VideosByCategory',
            component: videoByCategory,
            meta: {layout: 'Frontend', title: 'Dashboard', requiresAuth: true, page: 'videopage'},
        },
        {
            path: '/admin',
            name: 'HomePage',
            component: index,
            meta: {layout: 'Admin', title: 'Dashboard', requiresAuth: true, isAdmin: true, page: 'adminHome'},
        },
        {
            path: '/admin/users/add',
            name: 'Kullanıcı Ekle',
            component: addUser,
            meta: {layout: 'Admin', title: 'Dashboard', requiresAuth: true, isAdmin: true, page: 'users'},
        },
        {
            path: '/admin/users',
            name: 'Users',
            component: getUsers,
            meta: {layout: 'Admin', title: 'Dashboard', requiresAuth: true, isAdmin: true, page: 'users'},
        },
        {
            path: '/admin/questions',
            name: 'Questions',
            component: getQuestions,
            meta: {layout: 'Admin', title: 'Dashboard', requiresAuth: true, isAdmin: true, page: 'questions'},
        },
        {
            path: '/admin/users/:id',
            name: 'User',
            component: editUser,
            meta: {layout: 'Admin', title: 'Dashboard', requiresAuth: true, isAdmin: true, page: 'users'},
        },
        {
            path: '/admin/categories',
            name: 'Kategoriler',
            component: getCategories,
            meta: {layout: 'Admin', title: 'Dashboard', requiresAuth: true, isAdmin: true, page: 'categories'},
        },
        {
            path: '/admin/categories/add',
            name: 'Kategori Ekle',
            component: addCategory,
            meta: {layout: 'Admin', title: 'Dashboard', requiresAuth: true, isAdmin: true, page: 'categories'},
        },
        {
            path: '/admin/categories/:id',
            name: 'Kullanıcı Edit',
            component: editCategory,
            meta: {layout: 'Admin', title: 'Dashboard', requiresAuth: true, isAdmin: true, page: 'categories'},
        },
        {
            path: '/admin/videos/add',
            name: 'Video Ekle',
            component: addVideo,
            meta: {layout: 'Admin', title: 'Dashboard', requiresAuth: true, isAdmin: true, page: 'videos'},
        },
        {
            path: '/admin/videos',
            name: 'Videolar',
            component: getVideos,
            meta: {layout: 'Admin', title: 'Dashboard', requiresAuth: true, isAdmin: true, page: 'videos'},
        },
        {
            path: '/admin/videos/:id',
            name: 'Video Edit',
            component: editVideo,
            meta: {layout: 'Admin', title: 'Dashboard', requiresAuth: true, isAdmin: true, page: 'videos'},
        },
        {
            path: '/admin/notifications/add',
            name: 'Duyuru Ekle',
            component: addNotification,
            meta: {layout: 'Admin', title: 'Dashboard', requiresAuth: true, isAdmin: true, page: 'notifications'},
        },
        {
            path: '/admin/notifications',
            name: 'Duyurular',
            component: getNotifications,
            meta: {layout: 'Admin', title: 'Dashboard', requiresAuth: true, isAdmin: true, page: 'notifications'},
        },
        {
            path: '/admin/notifications/:id',
            name: 'Duyuru Edit',
            component: editNotification,
            meta: {layout: 'Admin', title: 'Dashboard', requiresAuth: true, isAdmin: true, page: 'notifications'},
        },
        {
            path: '/login',
            name: 'Login',
            component: login,
            meta: {layout: 'Login', title: 'Dashboard', page: 'login'},
        },
        { 
            path: '*',
            component: notFound,
            name: '404',
            meta: {layout: 'Default', title: 'Not Found', page: '404'},
         },
    ]

});

// eslint-disable-next-line
router.beforeEach(async (to, from, next) => {
    store.dispatch('error/removeError');
    const token = sessionStorage.getItem('token');
    if(to.meta.requiresAuth) {
        const result = await get(`/users/verifytoken`, {token});
        if(result.success && store.state.users.user) {
            if(to.meta.isAdmin) {
                if(store.state.users.user) {
                    const { permission } = store.state.users.user;
                        if(permission.includes(to.meta.page) || to.meta.page === 'adminHome') {
                            next();
                        } else {
                            store.dispatch('error/setError', 'Bu sayfaya giriş yetkiniz bulunmamaktadır!');
                        }
                } else {
                    // store.dispatch('users/logoutUser', { _id: store.state.users.user._id }); //TODO: Sonra hallet
                    next('/login');
                }
            } else {
                next();
            }
        } else {
            // store.dispatch('users/logoutUser', { _id: store.state.users.user._id }); //TODO: Sonra hallet
            next('/login');
        }
    } else {
        next();
    }
});

export default router;