<template>
    <Row :gutter="gutter">
    <i-col span="16">
      <Form :model="form" :label-width="130" ref="formInline" :rules="rules">
        <FormItem label="Video Adı" prop="name">
            <Input v-model="form.name" maxlength="30"/>
        </FormItem>
        <FormItem label="Link" prop="link">
            <Input v-model="form.link"/>
        </FormItem>
        <FormItem label="Embed" prop="embed">
            <Input type="textarea" :rows="5" v-model="form.embed"/>
        </FormItem>        
        <FormItem label="Konu" prop="subject">
            <Input v-model="form.subject" type="textarea" :rows="4" />
        </FormItem>
        <FormItem label="Tip Not" prop="highlight">
            <Input v-model="form.highlight" maxlength="30"/>
        </FormItem>
        <FormItem label="Video Süresi" prop="duration">
            <Input v-model="form.duration" v-mask="'##:##:##'" maxlength="9"/>
        </FormItem>
        <FormItem label="Kategori" prop="type">
            <Select v-model="form.category" style="width:200px">
                <Option v-for="item in categories" :value="item._id" :key="item._id">{{ item.name }}</Option>
            </Select>
        </FormItem>
        <FormItem label="Kapak Resmi">
            <Upload
                :before-upload="handleUpload"
                :format="['jpg','jpeg','png']"
                :max-size="2048"
                action=""
                >
                <Button icon="ios-cloud-upload-outline">Yükle</Button>
            </Upload>
            <div v-if="file">
                Yüklenecek dosya: "{{ form.cover.name }}"
                <Button type="text" shape="circle" icon="md-trash" @click="file = null"></Button>
            </div>
        </FormItem>
        <FormItem>
          <Checkbox v-model="form.live">Canlı</Checkbox>
        </FormItem>
        <div v-if="form.live">
            <FormItem label="Başlangıç Tarihi" prop="startdate">
                <DatePicker type="datetime" :time-picker-options="{steps: [1, 10, 10]}" v-model="form.startdate" style="width: 200px"></DatePicker>
            </FormItem>
            <FormItem label="Bitiş Tarihi" prop="enddate">
                <DatePicker type="datetime" :time-picker-options="{steps: [1, 10, 10]}" v-model="form.enddate" style="width: 200px"></DatePicker>
            </FormItem>
        </div>
        <FormItem label="Anahtar Kelimeler" prop="keyword">
                <Tag v-for="(item, i) in form.keyword" :key="item" :name="item" closable @on-close="removeTag(i)">{{ item }}</Tag>
                <Input v-on:keyup.enter.native="addTags(tag)" v-model="tag" style="width: 200px;" />
        </FormItem>
        <FormItem>
          <Checkbox v-model="form.isBunny">Bunny videosu mu?</Checkbox>
        </FormItem>
        <FormItem>
          <Checkbox v-model="form.status">Aktif</Checkbox>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="handleSubmit('formInline')">Kaydet</Button>
            <Button style="margin-left: 8px" to="/admin/videos">Kapat</Button>
        </FormItem>
      </Form>
    </i-col>
    <i-col span="8">
    </i-col>
  </Row>
</template>

<script>
import { post, get } from '@/api';
import { mask } from 'vue-the-mask';
export default {
    directives: { mask },
    data() {
        return {
            gutter: 15,
            form: {
                keyword: [],
            },
            file: null,
            categories: null,
            tag: null,
            rules: {
                // tcno: [
                //     { validator: validateNumber, trigger: 'blur' }
                // ],
                // firstname: [
                //     { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
                // ],
                // surname: [
                //     { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
                // ],
                // email: [
                //     { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
                // ]
            }
        }
    },

    methods: {
        handleSubmit(name) {
            this.$refs[name].validate(async (valid) => {
                if (valid) {
                    if(!this.form.live) {
                        delete(this.form.startdate);
                        delete(this.form.enddate);
                    }
                    const r = await post('/videos/add', this.form);
                    if(r.success) {
                        this.$swal('Kayıt Başarılı!');
                    } else {
                        this.$swal(r.error.response.data.error);
                    }
                } else {
                    this.$Message.error('Fail!');
                }
            });
        },
        handleUpload (file) {
            this.file = file;
            return false;
        },

        addTags(e) {
            e && e.length > 2 && this.form.keyword.indexOf(e.toLowerCase()) === -1 ? this.form.keyword.push(e.toLowerCase()): '';
            this.tag = null;
        },

        removeTag(e) {
            this.form.keyword.splice(e, 1);
        }
    },

    watch: {
        file(n) {
            n === null ? delete(this.form.cover) : this.form.cover = n; 
        }
    },

    async mounted() {
        let result;
        result = await get('/categories');
        this.categories = result.message.data;
    }
}
</script>

<style>

</style>