<template>
    <Table border :columns="columns" :data="videos">
        <template slot-scope="{ row }" slot="action">
            <Button type="primary" size="small" style="margin-right: 5px" @click="edit(row._id)">Düzenle</Button>
            <Button type="error" size="small" @click="remove(row._id)">Sil</Button>
        </template>
    </Table>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            columns: [
                {
                    title: 'Video Adı',
                    key: 'name'
                },
                {
                    title: 'Açıklama',
                    key: 'subject'
                },
                {
                    title: 'Anahtar Kelimeler',
                    key: 'keyword',
                    render: (h, {row}) => {
                        if(row.keyword) return row.keyword.map((e, i, arr) => i < (arr.length-1) ? h('span', e + ', ') : h('span', e));
                    },
                },
                {
                    title: 'Kategorisi',
                    key: 'category',
                    render: (h, {row}) => {
                        if(row.category) return h('span', row.category.name);
                    },
                },
                {
                    title: 'Sıralama',
                    key: 'order',
                    render: (h, {row}) => {
                        if(row.order) return h('span', row.order);
                    },
                },
                {
                    title: 'Özellikler',
                    slot: 'action',
                    width: 150,
                    align: 'center'
                }
            ],
        }
    },

    methods: {
        edit(x) {
            this.$router.push(`/admin/videos/${x}`);
        },

        remove(x) {
            this.$swal({
                title: "İlgili video sistemden kaldırılacaktır",
                text: "Bunu yapmak istediğinize emin misiniz?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Evet",
                cancelButtonText: "Hayır",
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch('videos/removeVideo', x);
                }
            });
        }
    },

    mounted() {
        this.$store.dispatch('videos/getAll');
    },

    computed: {
        ...mapGetters({
            videos: 'videos/getVideos',
        }),
    }
}
</script>

<style>

</style>