<template>
    <Row :gutter="gutter">
    <i-col span="16">
      <Form :model="form" :label-width="130" ref="formInline" :rules="rules">
        <FormItem label="Kategori Adı" prop="name">
            <Input v-model="form.name" maxlength="30"/>
        </FormItem>
        <FormItem label="Kategori" prop="type">
            <Select v-model="form.type" style="width:200px">
                <Option v-for="item in types" :value="item._id" :key="item._id">{{ item.name }}</Option>
            </Select>
        </FormItem>
        <FormItem label="Alt Kategori" prop="subtype">
            <Select v-model="form.subtype" style="width:200px">
                <Option v-for="item in subtypes" :value="item._id" :key="item._id">{{ item.name }}</Option>
            </Select>
        </FormItem>
        <FormItem label="Kapak Resmi">
            <Upload
                :before-upload="handleUpload"
                :format="['jpg','jpeg','png']"
                :max-size="2048"
                action=""
                >
                <Button icon="ios-cloud-upload-outline">Yükle</Button>
            </Upload>
            <div v-if="file">
                Yüklenecek dosya: "{{ form.cover.name || form.cover }}"
                <Button type="text" shape="circle" icon="md-trash" @click="deleteCover"></Button>
            </div>
        </FormItem>
        <FormItem label="Başlangıç Tarihi" prop="startdate">
            <DatePicker type="date" v-model="form.startdate" :value="computedDates" @on-change="(value) => form.startdate=value.toString()" style="width: 200px"></DatePicker>
        </FormItem>
        <FormItem label="Bitiş Tarihi" prop="enddate">
            <DatePicker type="date" v-model="form.enddate" :value="computedDates" @on-change="(value) => form.enddate=value.toString()" style="width: 200px"></DatePicker>
        </FormItem>
        <FormItem>
          <Checkbox v-model="form.status">Aktif</Checkbox>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="handleSubmit('formInline')">Kaydet</Button>
            <Button style="margin-left: 8px" to="/admin/categories">Kapat</Button>
        </FormItem>
      </Form>
    </i-col>
    <i-col span="8">
    </i-col>
  </Row>
</template>

<script>
import { get,post } from '@/api';
export default {
    data() {
        return {
            gutter: 15,
            form: {},
            file: null,
            types: [],
            subtypes: [],
            rules: {
                // tcno: [
                //     { validator: validateNumber, trigger: 'blur' }
                // ],
                // firstname: [
                //     { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
                // ],
                // surname: [
                //     { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
                // ],
                // email: [
                //     { required: true, message: 'Bu alan boş bırakılamaz!', trigger: 'blur' }
                // ]
            }
        }
    },
  methods: {
    computedDates () {
        return this.$moment(this.value).format('YYYY-MM-D');
    },
    handleSubmit(name) {
        this.$refs[name].validate(async (valid) => {
            if (valid) {
            const r = await post('/categories/add', this.form);
            if(r.success) {
                this.$swal('Kayıt Başarılı!');
            } else {
                this.$swal(r.error.response.data.error);
            }
            } else {
                this.$Message.error('Fail!');
            }
        });
    },
    handleUpload (file) {
        this.file = file;
        return false;
    },
    deleteCover() {
        this.file = null;
        this.form.cover = null;
    },
    changeTheStartDate(a) {
        this.form.startdate = a;
    },

    changeTheEndDate(a) {
        this.form.enddate = a;
    }
  },

  watch: {
      file(n) {
          n === null ? delete(this.form.cover) : this.form.cover = n; 
      }
  },

  async mounted() {
    let result;
    result = await get('/subtypes');
    this.subtypes = result.message.data;
    result = await get('/types');
    this.types = result.message.data;
    const {message} = await get(`/categories/${this.$route.params.id}`);
    this.form = message.data;
    this.form.startdate = new Date(this.form.startdate);
    this.form.enddate = new Date(this.form.enddate);
    if(message.data.cover) this.file = message.data.cover;
  }
}
</script>

<style>
.ivu-select-single .ivu-select-selection .ivu-select-placeholder, .ivu-select-single .ivu-select-selection .ivu-select-selected-value {
    line-height: 34px;
}
</style>