<template>
    <Table border :columns="columns" :data="questions">
        <template slot-scope="{ row }" slot="action">
            <Button type="error" size="small" @click="remove(row._id)">Sil</Button>
        </template>
    </Table>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            columns: [
                {
                    title: 'Öğrenci',
                    key: 'user',
                    render: (h, {row}) => {
                        if(row.user) return h('span', row.user.firstname + ' ' + row.user.surname);
                    },
                },
                {
                    title: 'Konu',
                    key: 'name',
                },
                {
                    title: 'Soru',
                    key: 'message',
                },
                {
                    title: 'Tarih',
                    key: 'created_at',
                    render: (h, {row}) => {
                        if(row.createdAt) return h('span', this.$moment(row.createdAt).format('DD.MM.YYYY'));
                    },
                },
                {
                    title: 'Özellikler',
                    slot: 'action',
                    width: 150,
                    align: 'center'
                }
            ],
        }
    },

    methods: {

        remove(x) {
            this.$swal({
                title: "Emin misin?",
                text: "İlgili soru sistemden kaldırılacaktır",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Evet",
                cancelButtonText: "Hayır",
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch('questions/removeQuestion', x);
                }
            });
        }
    },

    mounted() {
        this.$store.dispatch('questions/getAll');
    },

    computed: {
        ...mapGetters({
            questions: 'questions/getQuestions',
        }),
    }
}
</script>