import { get } from '@/api';
const state = () => ({
    questions: []
});

const actions = {
    async getAll({commit}) {
        const r = await get('/questions');
        if(r) commit('ADD', r.message.data);
    },

    async removeQuestion({commit}, id) {
        const r = await get(`/questions/remove/${id}`);
        if(r.success) commit('REMOVE', id);
    }
}

const mutations = {
    ADD(state, data) {
        state.questions = data;
    },
    REMOVE(state, data) {
        state.questions = state.questions.filter(v => v._id !== data);
    }
}

const getters = {
    getQuestions(state) {
        return state.questions;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}