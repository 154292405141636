import { get } from '@/api';
const state = () => ({
    users: [],
    user: null,
    right: null,
    notice: null,
    video: null,
    lecture: null,
    live: null,
});

const actions = {
    async getAll({commit}, page = 1) {
        const r = await get('/users?page=' + page);
        if(r) commit('ADD', r.message.data);
    },

    async searchUser({commit}, tcno) {
        const r = await get(`/users/search/${tcno}`);
        if(r) commit('ADD', r.message.data);
    },

    async removeUser({commit}, id) {
        const r = await get(`/users/remove/${id}`);
        if(r.success) commit('REMOVE', id);
    },

    // for frontend
    async setUser({commit}, data) {
        commit('ADD_USER', data);
    },

    async logoutUser({commit, dispatch}, data) {
        await get('/users/logout', data);
        sessionStorage.removeItem('token');
        dispatch('notifications/setRead', false, {root: true});
        commit('LOGOUT_USER');
    },

    async getRight({commit}, data) {
        const r = await get('/rights/getonebyuser', data);
        if(r.success){
            commit('ADD_RIGHT', r.message.data.remain);
        } else {
            commit('REMOVE_ALL_RIGHT');
        }
    },

    async getLive({commit}, data) {
        const r = await get('/videos/getlive', data);
        if(r.success) {
            commit('ADD_LIVE', r.message.data[0]);
        } else {
            commit('REMOVE_LIVE');
        }
    },

    async getLectures(app, user_id) {
        const r = await get(`/lectures/getlecturesbyuser/${user_id}`);
        if(r.success) {
            app.commit('ADD_LECTURES', r.message.data);
        } else {
            app.commit('CLEAR_LECTURES');
        }
    },

    async getNotice({commit}, data) {
        const r = await get(`/notices/${data.user_id}/${data.video_id}`);
        if(r.success) {
            commit('ADD_NOTICE', r.message.data);
        }else {
            commit('REMOVE_NOTICE');
        }
    },

    async getVideo({commit}, data) {
        const r = await get(`/videos/${data.video_id}`);
        if(r.success) {
            commit('ADD_VIDEO', r.message.data);
        } else {
            commit('REMOVE_VIDEO');
        }
    },

    async subtractRight({commit}, data) {
        await get('/rights/subtractone', data);
        commit('REMOVE_RIGHT');
    }
}

const mutations = {
    ADD(state, data) {
        state.users = data;
    },
    
    ADD_USER(state, data) {
        state.user = data;
    },

    LOGOUT_USER(state) {
        state.user = null,
        state.users = [],
        state.right = null,
        state.notice = null,
        state.video = null;
    },

    REMOVE_USER(state) {
        state.user = null;
    },

    REMOVE(state, data) {
        state.users = state.users.filter(v => v._id !== data);
    },

    ADD_RIGHT(state, data) {
        state.right = data;
    },

    REMOVE_RIGHT(state) {
        state.right = state.right - 1;
    },

    REMOVE_ALL_RIGHT(state) {
        state.right = null
    },

    REMOVE_VIDEO(state) {
        state.video = null;
    },

    ADD_NOTICE(state, data) {
        state.notice = data;
    },

    REMOVE_NOTICE(state) {
        state.notice = null;
    },

    ADD_VIDEO(state, data) {
        state.video = data;
    },

    ADD_LECTURES(state, data) {
        state.lecture = data;
    },

    REMOVE_LECTURES(state) {
        state.lecture = null;
    },

    ADD_LIVE(state, data) {
        state.live = data;
    },

    REMOVE_LIVE(state) {
        state.live = null;
    }
}

const getters = {
    getUsers(state) {
        return state.users;
    },
    getUser(state) {
        return state.user;
    },
    getRight(state) {
        return state.right;
    },
    getNotice(state) {
        return state.notice;
    },
    getVideo(state) {
        return state.video;
    },
    getLecture(state) {
        if(state.lecture) {
            return state.lecture.map(v => v.category_id);
        }
    },
    getLive(state) {
        return state.live;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}