import { get } from '@/api';
const state = () => ({
    videos: []
});

const actions = {
    async getAll({commit}) {
        const r = await get('/videos/getallvideos');
        if(r) commit('ADD', r.message.data);
    },

    async removeVideo({commit}, id) {
        const r = await get(`/videos/remove/${id}`);
        if(r.success) commit('REMOVE', id);
    }
}

const mutations = {
    ADD(state, data) {
        state.videos = data;
    },
    REMOVE(state, data) {
        state.videos = state.videos.filter(v => v._id !== data);
    }
}

const getters = {
    getVideos(state) {
        return state.videos;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}