<template>
  <Layout>
    <Header></Header>
    <Section class="video-section">
        <Row :gutter="15">
            <i-col>
                <div class="video-header">
                    <Button icon="ios-arrow-back" @click="$router.go(-1)" type="error" size="small" class="come-back-button">Geri Dön</Button>
                    <div class="come-back"></div>
                    <hr/>
                </div>
            </i-col>
            <i-col span="24">
                <ul class="video-grid">
                    <li v-for="(item, i) in videos" :key="item._id" @click="handleSlideClick(item._id)">
                        <div class="card">
                            <div class="card-body">
                              <div class="card-budget" v-if="item.highlight">{{ item.highlight }}</div>
                              <div class="gradient" @mouseover="handleHover(i)" @mouseleave="handleHover(i)"></div>
                              <div class="card-image" v-if="env === 'development'" :ref="`element-${i}`" :style="{ backgroundImage: 'url('+require('@/assets/no-image.png')+')' }"></div>
                              <div class="card-image" else :ref="`element-${i}`" :style="item.cover ? { backgroundImage: 'url('+upload_url+item.cover+')' } : { backgroundImage: 'url('+require('@/assets/no-image.png')+')' }"></div>
                              <div class="card-description" v-html="item.subject"></div>
                              <div class="card-time">{{ item.duration }}</div>
                            </div>
                            <div class="card-footer" v-html="item.name"></div>
                        </div>
                    </li>
                </ul>
            </i-col>
        </Row>
    </Section>
  </Layout>
</template>

<script>
import { get } from '@/api';
import Header from '@/components/frontend/Header';
export default {
  components: { Header },
  data(){
      return {
          upload_url: process.env.VUE_APP_UPLOAD_PATH,
          env: process.env.NODE_ENV,
          videos: null,
          notice: null,
          category: null,
      }
  },
  
  async mounted() {
    this.category = this.$route.params.category;
    const result = await get(`/videos/getvideosbycategory/${this.category}`);
    this.videos = result.message.data;
  },

  methods: {
    handleHover(i){
      const element = this.$refs[`element-${i}`];
      if(element[0].className.includes('scale')) {
        element[0].classList.remove('scale');
      } else {
        element[0].classList.add('scale');
      }
    },

    handleSlideClick(data) {
      if(data) {
        this.$router.push(`/video/${data}/${this.category}`);
      }
    },

  }

}
</script>

<style scoped>
    .video-section {
        margin: 10px 70px;
        position: relative;
    }

    .come-back-button {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .video-grid {
        list-style: none;
        padding-left: 0px;
        display: flex;
        flex-direction: columns;
        flex-wrap: wrap;
        justify-content: center;
    }

    .video-grid li {
        width: 245px;
        height: 333px;
        margin: 30px 10px;
        position: relative;
    }

  .video-grid .card {
    width: 100%;
    height: 100%;
    margin: 5px;
    position: relative;
    cursor: pointer;
  }

  .video-grid .card .card-body {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .video-grid .card .card-body .card-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform .5s;
  }

  .video-grid .card .card-body .card-budget {
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #ae3620;
    min-width: 80px;
    height: 25px;
    padding: 0px 5px;
    word-wrap: break-word;
    color: #FFF;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    line-height: 24px;
    z-index: 11;
  }

  .video-grid .card .card-body .gradient {
    background: rgb(20,20,20);
    background: -moz-linear-gradient(0deg, rgba(20,20,20,1) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(20,20,20,1) 0%, rgba(0,0,0,0) 100%);
    background: linear-gradient(0deg, rgba(20,20,20,1) 0%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#141414",endColorstr="#000000",GradientType=1);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
  }

  .video-grid .card .card-body .card-description {
    position: absolute;
    width: 100%;
    bottom: 20px;
    font-size: 13px;
    left: 5px;
    color: #e3e3e3;
    white-space: pre-wrap;
    word-wrap: break-word;
    z-index: 11;
  }

  .video-grid .card .card-body .card-time {
    position: absolute;
    bottom: 0px;
    right: 5px;
    font-size: 12px;
    color: #e3e3e3;
    white-space: pre-wrap;
    word-wrap: break-word;
    z-index: 11;
  }

  .video-grid .card .card-footer {
    height: 37px;
    padding: 4px;
    display: flex;
    align-items: center;
    background-color: #222222;
    border-bottom: 1px solid #2f2f2f;
    border-left: 1px solid #2f2f2f;
    border-right: 1px solid #2f2f2f;
    color: #e3e3e3;
    font-size: 16px;
    font-weight: 700;
  }

  .scale {
    transform: scale(1.2);
  }
</style>