<template>
<div class="body-wrapper" :style="bg">
  <div class="login-wrapper">
    <img src="@/assets/logo.png" class="logo" />
    <slot/>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      bg: {
        backgroundImage: `url(${require('@/assets/bg.jpg')})`
      }
    }
  }
};
</script>

<style scoped>  
  .login-wrapper {
    position: absolute;
    transform: translate(-50%, 0);
    top: 100px;
    left: 50%;
    padding: 30px 40px;
    border: 1px solid #444343;
    border-radius: 10px;
    background-color: #2f2e2e;
    width: 390px;
  }

  .body-wrapper {
    height: 100vh;
    width: 100vw;
    background-color: #222222;
    background-size: cover;
  }

  .logo {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
</style>